






































































































































import VueTypedMixins from "vue-typed-mixins";
import ChainSearch from "@/components/pc/BlockChainBrowser/ChainSearch.vue";
import PageContainer from "@/components/pc/BlockChainBrowser/PageContainer.vue";
import Count from "@/components/pc/BlockChainBrowser/Count.vue";
import blockMixin from "@/mixin/block";
export default VueTypedMixins(blockMixin).extend({
  components: { ChainSearch, PageContainer, Count },
});
