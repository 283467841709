import Vue from "vue";
import { message } from "ant-design-vue";
import { iBlockInBlockMixin } from "@/types/block";
import { getBlocks, getBlocksCount } from "@/service/api";

export default Vue.extend({
  data() {
    return {
      searchValue: "",
      Loading: false,
      pages: {
        currentPage: 1,
        pageSize: 20,
        total: 10,
      },
      showTotal: 0,
      range: 0,
      Blocks: [] as iBlockInBlockMixin[],
    };
  },
  computed: {
    selectOptions() {
      return [
        {
          label: this.$t('lang.block.title'),
          value: 0
        },
        {
          label: this.$t('lang.block.txnsBlocks', [100]),
          value: 100
        },
        {
          label: this.$t('lang.block.txnsBlocks', [200]),
          value: 200
        },
        {
          label: this.$t('lang.block.txnsBlocks', [500]),
          value: 500
        },
        {
          label: this.$t('lang.block.txnsBlocks', [1000]),
          value: 1000
        },
        {
          label: this.$t('lang.block.txnsBlocks', [2000]),
          value: 2000
        },
        {
          label: this.$t('lang.block.txnsBlocks', [5000]),
          value: 5000
        }
      ]
    }
  },
  mounted() {
    this.getBlocks();
    this.getBlocksCount()
  },
  methods: {
    pageChange(page: number) {
      this.pages.currentPage = page;
      this.getBlocks();
    },
    sizeChange(size: number) {
      this.pages.pageSize = size;
      this.pageChange(1);
    },
    filterBlocks(range: number) {
      this.pages.currentPage = 1
      this.range = range
      this.getBlocksCount()
      this.getBlocks()
    },
    async getBlocksCount() {
      const { result } = await getBlocksCount(this.range)
      this.showTotal = result
      this.pages.total = result > 100000 ? 100000 : result;
    },
    async getBlocks() {
      this.Loading = true;
      try {
        const res = await getBlocks(this.pages.currentPage, this.pages.pageSize, this.range)
        if (res.result) {
          this.Blocks = res.result
          this.Loading = false
        }
        else throw (res.error)
      } catch (e) {
        this.Loading = false;
        message.warning({
          content: e as string,
        });
      }
    },
  }
});
